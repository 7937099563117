import { Fragment, ReactElement } from "react";
import { ProductSortColumn, ProductSortInput, Sort } from "./gql/graphql";
import { SortCatalogBy } from "pages/ProductsCatalog/types";

// eslint-disable-next-line import/prefer-default-export
export const SUPPORT_EMAIL_ADDRESS = "support@tangiblematerials.com";

const RECYCLED_CONTENT_GLOSSARY_CONTENT = {
  descriptionElement: (
    <Fragment>
      <p className="editorial-body">
        If a product is labeled “recycled content,” it means some portion of the
        product is made of reused materials. Many products contain high levels
        of recycled content (50%+), but it is often the case that some new
        materials are used in the creation of these products as well. Recycled
        content, including pre-consumer and/or post-consumer material, recorded
        in Tangible have been certified through{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ul.com/services/recycled-content-validation"
          className="link "
        >
          UL
        </a>
        ,{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.scsglobalservices.com/services/recycled-content-certification"
          className="link "
        >
          SCS Global Services
        </a>
        , or{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.greencirclecertified.com/product-certifications"
          className="link "
        >
          GreenCircle Certified
        </a>
        .
      </p>
    </Fragment>
  ),
  whyElement: (
    <p className="editorial-body">
      There is far too much stuff in the world already. (One slightly depressing
      fact:{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.theguardian.com/environment/2020/dec/09/human-made-materials-now-outweigh-earths-entire-biomass-study#:~:text=They%20found%20that%20at%20the,the%20impact%20on%20plant%20biomass."
        className="link "
      >
        man-made materials now outweigh all biomass on Earth
      </a>
      .) The extraction and processing of natural resources often has hugely
      negative effects on local / frontline communities, resulting in
      contaminated air, water, and soil. As such, we applaud the manufacturers
      who are coming up with innovative ways of using pre-existing materials.
    </p>
  ),
};

export const GLOSSARY_TERMS: {
  [code: string]: {
    anchorId: string;
    descriptionElement: ReactElement;
    title: string;
    whyElement?: ReactElement;
  };
} = {
  INNOVATIVE: {
    anchorId: "innovative",
    title: "INNOVATIVE PRODUCTS",
    descriptionElement: (
      <Fragment>
        <p className="editorial-body">
          “Innovative products" are products that use familiar materials in an
          unconventional way to make healthier, more sustainable products for
          the built environment. There’s no singular definition for this, but we
          wanted to highlight the organizations that are breaking the typical
          manufacturing mold and coming up with the materials of the future.
          (Read: used denim insulation and carbon-sequestering paint.) These
          materials have the potential to reduce waste, reduce carbon, and
          eliminate harmful chemicals from the spaces where we spend 90% of our
          time.
        </p>
        <br />
        <p className="editorial-body">
          These materials are often produced by smaller manufacturers or even
          design studios, which means 1) they may not be available in large
          quantities, and 2) the organizations may not have gone through a third
          party-verified Environmental Product Declaration. However, this is not
          always the case.
        </p>
      </Fragment>
    ),
    whyElement: (
      <p className="editorial-body">
        If we think about all of the stuff on Earth, it’s clear that we can’t
        continue doing things in the same way. We can’t keep mining and
        harvesting materials using the same extractive processes. Thus, it’s
        important to push ourselves to think differently about how buildings can
        result in systems regeneration.
      </p>
    ),
  },
  RECYCLED: {
    ...RECYCLED_CONTENT_GLOSSARY_CONTENT,
    anchorId: "recycle",
    title: "RECYCLED CONTENT",
  },
  PRE_CONSUMER_RECYCLED: {
    ...RECYCLED_CONTENT_GLOSSARY_CONTENT,
    anchorId: "recycle",
    title: "PRE-CONSUMER RECYCLED CONTENT",
  },
  POST_CONSUMER_RECYCLED: {
    ...RECYCLED_CONTENT_GLOSSARY_CONTENT,
    anchorId: "recycle",
    title: "POST-CONSUMER RECYCLED CONTENT",
  },
  BIO_CONTENT: {
    anchorId: "bio-based",
    title: "BIO-BASED CONTENT",
    descriptionElement: (
      <Fragment>
        <p className="editorial-body">
          If a product is labeled “bio-based content,” it means that a portion
          of the product is made of organic matter. Some products contain 90%+
          bio-based content, but many do use synthetic binders which means the
          products are not 100% compostable. Tangible reports bio-based content
          that has been certified through{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ul.com/services/recycled-content-validation"
            className="link "
          >
            UL
          </a>
          ,{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://biopreferred.gov/BioPreferred/faces/pages/BiobasedProducts.xhtml"
            className="link "
          >
            USDA BioPreferred
          </a>
          , ASTM D6866, or{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.greencirclecertified.com/product-certifications"
            className="link "
          >
            GreenCircle Certified
          </a>
          .
          <br />
        </p>
      </Fragment>
    ),
    whyElement: (
      <Fragment>
        <p className="editorial-body">
          Microplastics are everywhere (including the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.npr.org/2022/04/06/1091246691/microplastics-found-in-human-lungs"
            className="link "
          >
            lungs of living people
          </a>{" "}
          and the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.nationalgeographic.com/environment/article/microplastic-pollution-is-found-in-deep-sea"
            className="link "
          >
            deep sea
          </a>
          ). Beyond the polluting effects of ingesting plastics on humans and
          other animals, plastic-based products are also often made from
          petroleum (otherwise known as oil), which of course, furthers climate
          change.
        </p>
        <br />
        <p className="editorial-body">
          As a society, it’s clear that we must transition away from using
          plastics, including in the built environment. Using biologically-based
          ingredients instead of plastics is something that more and more
          manufacturers are trying to do, so we’ve highlighted the ones that
          declare this.
        </p>
      </Fragment>
    ),
  },
  RED_LIST_FREE: {
    anchorId: "red-list-free",
    title: "RED LIST FREE",
    descriptionElement: (
      <Fragment>
        <p className="editorial-body">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://living-future.org/declare/declare-about/red-list/"
            className="link "
          >
            The Red List
          </a>
          , developed by{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://living-future.org/"
            className="link "
          >
            ILFI
          </a>
          , is the “worst in class” chemicals that are toxic for human health.
          Red List Free products are certified to be free of these chemicals.
        </p>
      </Fragment>
    ),
    whyElement: (
      <Fragment>
        <p className="editorial-body">
          We need buildings to stop effectively poisoning people. It’s honestly
          pretty shocking what kinds of chemicals are still allowed to be used
          in all kinds of products, including high-touch surfaces like flooring
          and gypsum board. We applaud manufacturers that have made the effort
          to avoid Red List chemicals and prioritize human health.
        </p>
        <br />
        <p className="editorial-body">
          (It’s worth saying that some of the products on the platform which are
          not labeled “Red List Free” are still free from these chemicals; they
          just haven’t gone through certification.)
        </p>
      </Fragment>
    ),
  },
  LOW_VOC: {
    anchorId: "low-voc",
    title: "LOW VOC-CERTIFIED",
    descriptionElement: (
      <Fragment>
        <p className="editorial-body">
          VOCs, or{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.epa.gov/indoor-air-quality-iaq/what-are-volatile-organic-compounds-vocs#:~:text=Volatile%20organic%20compounds%20(VOCs)%20are,ten%20times%20higher)%20than%20outdoors."
            className="link"
          >
            volatile organic compounds
          </a>
          , are chemicals that “off-gas” from products and are inhaled by
          building occupants. Products marked as “Low VOC-Certified” have gone
          through testing (such as the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ul.com/resources/ul-greenguard-certification-program"
            className="link"
          >
            UL GREENGUARD Certification Program
          </a>
          ) to attest that they meet standards around indoor air pollution and
          chemical exposure. Other certifications include SCS and Eurofins.
        </p>
      </Fragment>
    ),
    whyElement: (
      <Fragment>
        <p className="editorial-body">
          VOCs are extremely harmful to human health because the compounds are
          so small and therefore easily absorbed by human lungs. An estimated 7
          million people a year die a premature death from air pollution, some
          of which is indoor air pollution. EPA{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.epa.gov/indoor-air-quality-iaq/what-are-volatile-organic-compounds-vocs#:~:text=Volatile%20organic%20compounds%20(VOCs)%20are,ten%20times%20higher)%20than%20outdoors."
            className="link "
          >
            studies
          </a>{" "}
          have found “levels of about a dozen common organic pollutants to be 2
          to 5 times higher inside homes than outside, regardless of whether the
          homes were located in rural or highly industrial areas.”
        </p>
        <br />
        <p className="editorial-body">
          We spend upwards of{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.epa.gov/report-environment/indoor-air-quality#:~:text=Americans%2C%20on%20average%2C%20spend%20approximately,higher%20than%20typical%20outdoor%20concentrations."
            className="link "
          >
            90% of our lives indoors
          </a>
          , so breathing in chemicals can have a lasting impact on our health.
          We want to encourage the use of building products that have made a
          point to avoid VOCs.
        </p>
      </Fragment>
    ),
  },
  // cbl: {
  //   anchorId: "carbon-reduction",
  //   title: "CARBON BASELINES",
  //   descriptionElement: (
  //     <Fragment>
  //       <p className="editorial-body">
  //         Our baselines for product-specific carbon footprints come from the{" "}
  //         <a
  //           target="_blank"
  //           rel="noreferrer"
  //           href="https://carbonleadershipforum.org/clf-material-baselines-updated-july-2021/"
  //           className="link"
  //         >
  //           Carbon Leadership Forum Material Baselines
  //         </a>
  //         . They have aggregated industry data for a large number of product
  //         categories and determined 80th, 50th, and 20th percentiles. We use the
  //         80th percentile as our baseline, which is a carbon footprint which 80
  //         percent of products in this category fall under. Carbon reduction
  //         numbers on the Tangible site then calculate what percent reduction in
  //         carbon a given product will result in, based on this baseline. For
  //         example, if the 80th percentile baseline is 50kg CO2e/m2 and a given
  //         product is 5kg CO2/m2, this is a 90% reduction in carbon compared to
  //         baseline.
  //         <br />
  //         <br />
  //         This also informs the standards for the products we feature on the
  //         platform. We only allow products that are at the 50th percentile or
  //         below in terms of carbon footprint for their product category.
  //         However, this is only for the products which have conducted a 3rd
  //         party-verified Environmental Product Declaration (EPD). Other products
  //         that do not have an EPD must meet some other sustainability
  //         qualification.
  //       </p>
  //       <br />
  //     </Fragment>
  //   ),
  //   whyElement: (
  //     <Fragment>
  //       <p className="editorial-body">
  //         The construction industry needs to reduce our collective carbon
  //         footprint now.
  //       </p>
  //       <br />
  //       <p className="editorial-body">
  //         In order to facilitate better decision-making, we believe it’s
  //         important to understand the context for a product’s carbon footprint
  //         number. 5kg CO2/m2 doesn’t mean much by itself, but a 90% reduction
  //         compared to the baseline for that product does. We want to take the
  //         guesswork out, so you as an industry actor can be better informed
  //         without having to be an expert in sustainability.
  //       </p>
  //     </Fragment>
  //   ),
  // },
};

export const PAGINATION_RESULT_COUNT = 36;

export const TANGIBLE_AWS_BUCKET_URL =
  "https://app-dot-tangible-materials.s3.us-west-1.amazonaws.com";

export const PLACEHOLDER_PRODUCT_IMG_SRC = `${TANGIBLE_AWS_BUCKET_URL}/placeholder-product-image.png`;

export const PLACEHOLDER_PROJECT_IMG_SRC = `${TANGIBLE_AWS_BUCKET_URL}/placeholder-project-image.png`;

export const GOOGLE_MAPS_API_KEY = "AIzaSyDvFEJERE-RULaQfKM_ah9gAnqkBARmdoc";

type CatalogSortOption = {
  config: ProductSortInput;
  displayLabel: SortCatalogBy;
  id: string;
};

export const CATALOG_SORT_OPTIONS: {
  [key in SortCatalogBy]: CatalogSortOption;
} = {
  [SortCatalogBy.absCarbonLoToHi]: {
    displayLabel: SortCatalogBy.absCarbonLoToHi,
    id: "absCarbonLoToHi",
    config: {
      by: ProductSortColumn.AbsoluteCarbon,
      direction: Sort.Ascending,
    },
  },
  [SortCatalogBy.absCarbonHiToLo]: {
    displayLabel: SortCatalogBy.absCarbonHiToLo,
    id: "absCarbonHiToLo",
    config: {
      by: ProductSortColumn.AbsoluteCarbon,
      direction: Sort.Descending,
    },
  },
  [SortCatalogBy.percentCarbonReductionLoToHi]: {
    displayLabel: SortCatalogBy.percentCarbonReductionLoToHi,
    id: "percentCarbonReductionLoToHi",
    config: {
      by: ProductSortColumn.CarbonReduction,
      direction: Sort.Ascending,
    },
  },
  [SortCatalogBy.percentCarbonReductionHiToLo]: {
    displayLabel: SortCatalogBy.percentCarbonReductionHiToLo,
    id: "percentCarbonReductionHiToLo",
    config: {
      by: ProductSortColumn.CarbonReduction,
      direction: Sort.Descending,
    },
  },
  [SortCatalogBy.dateAdded]: {
    displayLabel: SortCatalogBy.dateAdded,
    id: "dateAdded",
    config: {
      by: ProductSortColumn.CreatedAt,
      direction: Sort.Descending,
    },
  },
};

export const PROJECT_AREA_UNITS = {
  FT2: {
    value: "FT2",
    label: "ft2",
  },
  M2: {
    value: "M2",
    label: "m2",
  },
};

export const COLOR_PALETTE = {
  almostBlack: "#1e1e1e",
  weCallItRed: "#ed4e0a",
  sand: "#EBD8BD",
  sandFocusVisible: "#f7e4ca",
  lightSand: "#f9f3eb",
  darkSand: "#D9A152",
  mediumGrey: "#727272",
  mediumGrey2: "#B5B5B5",
  lightGrey: "#D8D8D8",
  superLightGrey: "#EFEFEF",
  darkGrey: "#4F4F4F",
  white: "#FFFFFF",
  blue: "#86A2CB",
  lightBlue: "#EDF3F4",
  lightFill: "#f8f7f7",
  flagRed: "#EFAF93",
};

export const TYPEFACES = {
  heading: "Epilogue, sans-serif",
  body: "Inter, sans-serif",
  contrast: "Roboto Mono, monospace",
};

const READY_MIX_AND_SHOTCRETE_BASELINE_INFO =
  "For Ready Mix Concrete and Shotcrete, reductions are calculated by comparing to 2022 v3.2 NRMCA (National Ready Mix Concrete Association) Regional Benchmarks.";

export const BASELINE_INFO_SOURCE = {
  ReadyMix: READY_MIX_AND_SHOTCRETE_BASELINE_INFO,
  Shotcrete: READY_MIX_AND_SHOTCRETE_BASELINE_INFO,
  generic:
    "Reductions are calculated by comparing to industry average products.",
};

export const GID_PREFIX = "gid://";

export enum LoggedOutRoutes {
  Login = "login",
  ResetPassword = "/reset-password/:uid/:token",
  Root = "/",
  Register = "register",
  RegisterSuccess = "register-success",
}

export const CSRF_TOKEN_QUERY_PARAM = "csrf_token";
export const CLEAR_SITE_DATA_QUERY_PARAM = "clear_site_data";
export type ClearSiteDataValues =
  | "*"
  | "cache"
  | "clientHints"
  | "cookies"
  | "executionContacts"
  | "storage";

export const ExternalLinks = {
  Methodology: "https://docs.tangiblematerials.com",
  WhatsNew:
    "https://docs.tangiblematerials.com/What-s-new-8bfb772cb67c4167a8c6747a7f2a2edc",
};

export const LIFECYCLE_STAGES = [
  {
    label: "Product Manufacturing",
    value: "A1A2A3",
    required: true,
  },
  {
    label: "Transportation",
    value: "A4",
    required: false,
  },
  {
    label: "Construction",
    value: "A5",
    required: false,
  },
  {
    label: "In Use",
    value: "B1",
    required: false,
  },
  {
    label: "Maintenance",
    value: "B2",
    required: false,
  },
  {
    label: "Repair",
    value: "B3",
    required: false,
  },
  {
    label: "Replacement",
    value: "B4",
    required: false,
  },
  {
    label: "Refurbishment",
    value: "B5",
    required: false,
  },
  {
    label: "Deconstruction",
    value: "C1",
    required: false,
  },
  {
    label: "Transport",
    value: "C2",
    required: false,
  },
  {
    label: "Reuse / Recycling",
    value: "C3",
    required: false,
  },
  {
    label: "Disposal",
    value: "C4",
    required: false,
  },
];
