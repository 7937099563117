import { useMemo } from "react";
import _ from "lodash";
import {
  ProjectDataFidelityEnum,
  ReportTotalImpactByBuildingElement,
} from "gql/graphql";

import "./CarbonByBuildingElementStackedBarChart.scss";

import SingleStackedBarChart from "./SingleStackedBarChart";

type Props = {
  data: ReportTotalImpactByBuildingElement[];
};

const customSort = (data: ReportTotalImpactByBuildingElement[]) =>
  _.cloneDeep(data).sort((a, b) => {
    const customSortOrder = ["STRUCTURE", "ENCLOSURE", "INTERIOR", "SERVICES"];
    return (
      customSortOrder.indexOf(a.buildingElements[0]) -
      customSortOrder.indexOf(b.buildingElements[0])
    );
  });

export const CarbonByBuildingElementStackedBarChart = ({ data }: Props) => {
  const formattedData = useMemo(
    () =>
      _.chain<ReportTotalImpactByBuildingElement>(customSort(data))
        .map((d) => ({
          key: _.lowerCase(_.join(d.buildingElements, "+")),
          label: _.join(_.map(d.buildingElements, _.capitalize), " + "),
          value: d.kgCo2e,
          parenthetical:
            d.dataFidelity === ProjectDataFidelityEnum.Estimated
              ? "estimated"
              : "",
          hatched: d.dataFidelity === ProjectDataFidelityEnum.Estimated,
        }))
        .value(),
    [data]
  );
  return (
    <SingleStackedBarChart
      visualizationId="building-elements-stacked-bar-chart"
      data={formattedData}
      title="Building Element"
      subtitle="A1-A5 emissions"
      tooltipTitle="Building Element"
    />
  );
};

export default CarbonByBuildingElementStackedBarChart;
