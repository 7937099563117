import { useContext, useRef } from "react";
import _ from "lodash";
import clsx from "clsx";

import WithLoadingState from "components/Reporting/WithLoadingState";
import DiagonalHatchPattern from "components/Reporting/DiagonalHatchPattern";
import VisualizationTooltip from "components/Reporting/VisualizationTooltip";
import { DataLoadingContext } from "context/DataLoadingContext";
import PortfolioFiltersContext from "context/PortfolioFiltersContext";
import { PortfolioProjectConnection } from "gql/graphql";
import { useContainerDimensions } from "utils/CustomHooks";
import { KGCO2E } from "utils/formatting";

import {
  BuildViz,
  EmissionsTargetConfig,
  MARGINS,
  VIZ_ID,
  buildVisualization,
} from "./utils";
import "./BuildingLeaderboardStackedBarChart.scss";
import UserAndOrganizationContext from "context/UserAndOrganizationContext";
import { useNavigate } from "react-router-dom";

interface Props {
  data: PortfolioProjectConnection["nodes"];
}

const defaultSBTiTargetConfig: EmissionsTargetConfig = {
  label: "SBTi target",
  description: "SBTi 2025 target (multifamily)",
  intensity: 348, // kg/m^2
};

const BuildingLeaderboardStackedBarChart = ({ data }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { dataLoading } = useContext(DataLoadingContext);

  const { filters } = useContext(PortfolioFiltersContext);

  const [{ currentOrganization }] = useContext(UserAndOrganizationContext);

  const fromConfig = currentOrganization?.config?.portfolioIntensityTarget;
  const intensityTargetConfig = {
    intensity: fromConfig?.intensity || defaultSBTiTargetConfig.intensity,
    description: fromConfig?.description || defaultSBTiTargetConfig.description,
    label: fromConfig?.label || defaultSBTiTargetConfig.label,
  };

  const navigate = useNavigate();

  useContainerDimensions<BuildViz>({
    containerRef,
    buildVisualization,
    buildVisualizationArgs: [
      data,
      filters.areaUnit,
      intensityTargetConfig,
      navigate,
    ],
    updateOnResize: true,
  });

  if (!data) {
    return null;
  }

  return (
    <div id={VIZ_ID}>
      <p className="subtitle-1">Building Performance</p>
      <p>
        Embodied Carbon Intensity{" "}
        {<KGCO2E withParentheses unit={filters.areaUnit} />}
      </p>
      <div role="figure">
        <WithLoadingState isLoading={dataLoading}>
          <div
            ref={containerRef}
            style={{ width: "100%", flex: 1, minHeight: 0 }}
          >
            <VisualizationTooltip />
            <svg width="100%">
              <g className="x-axis" />
              <g
                className="y-axis"
                transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
              />
              <line className="baseline" x1={MARGINS.left} />
              <line className="net-zero" />
              <g
                className="bars"
                transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
              />
              {_.map(
                ["structure", "enclosure", "interior", "services"],
                (buildingElement) => (
                  <DiagonalHatchPattern
                    key={buildingElement}
                    id={buildingElement}
                    size={4}
                  />
                )
              )}
            </svg>
          </div>
          <div className="legend">
            {_.map(
              [
                "Interior",
                "Enclosure",
                "Structure",
                "Services",
                "Combined",
                intensityTargetConfig.description,
              ],
              (label) => (
                <div
                  key={label}
                  className={clsx(["legend-item", _.toLower(label)])}
                  data-testid="legend-item"
                >
                  <div className="circle"></div>
                  <span className="label body-2">{label}</span>
                </div>
              )
            )}
          </div>
        </WithLoadingState>
      </div>
    </div>
  );
};

export default BuildingLeaderboardStackedBarChart;
