import { Add, ExpandMore, Search, Upload } from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { ReactNode, useCallback, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import PermissionRestricted from "components/PermissionRestricted";
import { UserPermissionsEnum } from "gql/graphql";
import "./ProjectActionsMenu.scss";
import { ModalContext } from "context/ModalContext";
import UploadEPDForm from "./UploadEPD/UploadEPDForm";
import UploadBuildingFilesModal from "./UploadBuildingFilesModal";

interface Props {
  label?: ReactNode;
}

const ProjectActionsMenu = ({ label = "Add data" }: Props) => {
  const navigate = useNavigate();
  const [menuOpen, _setMenuOpen] = useState<boolean>(false);
  const setMenuOpen = () => _setMenuOpen(true);
  const setMenuClose = () => _setMenuOpen(false);

  const anchorElRef = useRef<HTMLButtonElement | null>(null);

  const { getModal, modals } = useContext(ModalContext);
  const epdModalContext = getModal(UploadEPDForm, modals);
  const uploadBuildingFilesModalContext = getModal(
    UploadBuildingFilesModal,
    modals
  );

  const openEPDModal = useCallback(() => {
    epdModalContext?.open();
    setMenuClose();
  }, [epdModalContext]);

  const openBuildingFilesModal = useCallback(() => {
    uploadBuildingFilesModalContext?.open();
    setMenuClose();
  }, [uploadBuildingFilesModalContext]);

  return (
    <div id="project-actions-button-and-menu">
      <Button
        aria-controls={menuOpen ? "project-actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        color="secondary"
        id="project-actions-button"
        onClick={setMenuOpen}
        ref={anchorElRef}
        variant="outlined"
        endIcon={<ExpandMore />}
      >
        {label}
      </Button>
      <Menu
        id="project-actions-menu"
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={setMenuClose}
        MenuListProps={{
          "aria-labelledby": "project-actions-button",
        }}
        className="actions-menu"
      >
        <li className="label">Actions</li>
        <MenuItem onClick={openBuildingFilesModal}>
          <ListItemIcon>
            <Upload />
          </ListItemIcon>
          <ListItemText>Upload building files</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate("/products")}>
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText>Find products</ListItemText>
        </MenuItem>
        {/* TODO: are we getting rid of this one? */}
        <PermissionRestricted to={UserPermissionsEnum.CreateProduct}>
          <MenuItem onClick={openEPDModal}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText>Add EPD</ListItemText>
          </MenuItem>
        </PermissionRestricted>
      </Menu>
    </div>
  );
};

export default ProjectActionsMenu;
